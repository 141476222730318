import { inject, Injectable, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { distinctUntilChanged } from 'rxjs';
import { isEqual } from 'lodash';
import { UserData } from 'src/app/core/models/user-data';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class IntercomService implements OnInit {
  private readonly store = inject(Store);

  ngOnInit(): void {
    this._subscribeToUserData();
  }

  _subscribeToUserData() {
    this.store
      .pipe(select(UserDataFull), distinctUntilChanged(isEqual), untilDestroyed(this))
      .subscribe((data?: UserData) => {
        if (data && data.id_users) {
          this.updateIntercom({email: data.email, user_id: data.id_users, name: data.first_name});
        }
      });
  }

  bootIntercom(data: any): void {
    window.Intercom('boot', data);
  }

  shutdownIntercom(): void {
    window.Intercom('shutdown');
  }

  showIntercom(): void {
    window.Intercom('show');
  }

  updateIntercom(data?: any): void {
    const lastRequestAt = Math.floor(new Date().getTime() / 1000);
    window.Intercom('update', { last_request_at: lastRequestAt, ...data });
  }
}
